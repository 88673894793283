import "./src/styles/global.css"

import * as Sentry from "@sentry/react";

const replay = Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
});

Sentry.init({
    dsn: "https://ece3f2ec1fefbd596f1b6e6e5decfdc8@o1040791.ingest.us.sentry.io/4508072908750848",
    integrations: [
        Sentry.browserTracingIntegration(),
        replay,
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Session Replay
    // replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Listen to navigation events for when users land on our buggy URL, appropriately named: "/buggy-page/"
// and then start recording a replay.
navigation.addEventListener("navigate", (event) => {
    const url = new URL(event.destination.url);

    if (url.pathname.startsWith("/divorce/")) {
        replay.start();
    }
});
